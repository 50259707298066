<template>
  <div class="home">
    <div class="left-third">
      <transition name="text-slide"
                  mode="out-in">
        <div class="left-content"
             v-if="showText">
          <h1 class="title">

            {{title}}
            <!-- <a rel="home"
               itemprop="url">
            </a> -->
          </h1>

          <!-- <p>{{desc}}</p> -->
          <p>
            <b>全覆盖场景：</b>
            办公、游戏、娱乐，应有尽有。
          </p>
          <p>
            <b> 简单上手：</b>一用即会，无需学习成本。
          </p>
          <p>
            <b>海量网址和应用：</b>快速访问你常用的网站和应用。
          </p>
          <p>
            <b>动态壁纸：</b>每次新标签页都是新的惊喜。
          </p>
          <p>
            <b> 用户界面定制：</b>自定义主题和布局，打造属于你的浏览空间
          </p>

          <div class="btn_main one"
               @click="$router.push('/download')">
            <span>开始使用</span>
          </div>
          <div class="btn_main two"
               style="left: 180px;top: -44px;"
               @click="openWeb">
            <span>快速体验</span>
          </div>
        </div>
      </transition>
    </div>
    <div class="right-two-thirds">
      <transition name="image-fade"
                  mode="out-in">

        <div v-show="showImage"
             key="about-image"
             class="image-box">
          <img src="https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/pc.png"
               alt="关于页面的边框图片"
               class="image-border" />
          <img src="https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/home.png"
               alt="关于页面的内部图片"
               class="image-inner" />
        </div>
      </transition>
    </div>

    <div class="footer-pc">
      <p>南京迅跟信息科技有限公司 版权所有 © 2024 -&nbsp;
        <a href="https://beian.miit.gov.cn/"
           target="_blank"
           rel="noopener">苏ICP备18016433号-6</a>
      </p>
    </div>
    <div class="footer-phone">
      <p>南京迅跟信息科技有限公司 版权所有 © 2024 -&nbsp;

      </p>
      <a href="https://beian.miit.gov.cn/"
         target="_blank"
         rel="noopener">苏ICP备18016433号-6</a>
    </div>

  </div>
</template>

<script>
export default {
  name: 'home',
  data () {
    return {
      title: "XTab新标签页",
      desc: `
	  全覆盖的场景支持：从办公到游戏，从娱乐到学习，我们的插件满足你各种上网需求。

简单上手：即使是第一次使用，我们的界面设计让你一分钟内就能熟练掌握。

海量网址和卡片式应用：无需再次搜索，你最常访问的网站和最常用的应用一览无余。

动态壁纸：每次打开新标签页，都能看到新的壁纸，为你的上网体验增添美感和乐趣。

用户界面自定义：定制你喜欢的主题和布局，让浏览器成为个性化的工作和娱乐空间。
`,
      showText: false,
      showImage: false
    };
  },
  watch: {
    '$route' () {
      this.triggerAnimation();
    }
  },
  mounted () {
    this.triggerAnimation();
  },
  methods: {
    triggerAnimation () {
      this.showText = false;
      this.showImage = false;
      setTimeout(() => {
        this.showText = true;
        this.showImage = true;
      }, 100);
    },
    openWeb () {
      window.open('https://go.xtab.link/')
    },
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .home {
    /* height: 100%; */
    /* height: 100vh; */

    min-height: 100vh;
    background-image: url('https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/bg1.png');
    background-size: cover;
    padding-top: 100px;
  }

  .left-third {
    justify-content: center;
    align-items: center;
    position: relative;
    /*
    top: 100px; */
  }

  .left-content {
    transition: transform 1s ease;
    padding: 0 40px;
    /* text-align: left; */
    color: white;
  }

  .title {
    font-family: 'SHS-B';
    font-size: 32px;
    font-weight: bold;
    color: rgb(204, 118, 1);
    display: inline-block;
  }

  .btn_main {
    color: white;
    width: 158px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    cursor: pointer;
    margin: 30px auto; /* 左右居中的关键 */

    border-radius: 10px;
  }

  .btn_main:hover {
    transform: scale(1.05);
  }

  .btn_main.one {
    background: url('@/assets/img/btn_download.png') no-repeat 50% 0 / 100%;
  }
  .btn_main.one:hover {
    background-color: #e60000; /* 悬停时更深的红色 */
  }

  .btn_main.two {
    /* background: url('@/assets/img/btn_download.png') no-repeat 50% 0 / 100%; */
    background: rgb(42, 119, 196);
  }
  .btn_main.two:hover {
    background-color: rgb(57, 149, 241);
    /* background: linear-gradient(135deg, rgb(42, 119, 196), rgb(45, 150, 255)); */
  }

  .right-two-thirds {
    position: relative;
    /* top: 150px; */
  }

  .image-box {
    /* position: absolute; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-border {
    width: 100%;
    height: auto;

    transition: opacity 1s ease-in-out;
  }

  .image-inner {
    position: absolute;
    width: 76%; /* 这里可以调整内部图片大小 */
    height: auto;
    top: 36.4%;
    left: 50.4%;
    border-radius: 3% 3% 0 0;
    transform: translate(-50%, -50%); /* 将图片居中 */
    transition: opacity 1s ease-in-out;
  }

  .text-slide-enter-active,
  .text-slide-leave-active {
    transition: transform 1s;
  }

  .text-slide-enter,
  .text-slide-leave-to {
    transform: translateX(50%);
  }

  .text-slide-leave-active {
    transform: translateX(-100%);
  }

  .image-fade-enter-active,
  .image-fade-leave-active {
    transition: opacity 1s ease-in-out;
  }

  .image-fade-enter,
  .image-fade-leave-to {
    opacity: 0;
  }

  .image-fade-enter-to {
    opacity: 1;
  }

  .footer-phone {
    /* position: fixed; */
    /* bottom: 10px; */
    /* left: 0; */
    width: 100%; /* 宽度占满整个屏幕 */
    text-align: center; /* 文本居中 */
    color: white;
  }

  .footer-phone p {
    margin: 0;
    font-size: 10px;
  }
  .footer-phone a {
    text-decoration: none; /* 去除链接的下划线 */
    color: inherit; /* 继承父元素的文本颜色 */
    font-size: 10px;
  }

  .footer-phone a:hover {
    text-decoration: underline; /* 鼠标悬停时显示下划线 */
  }
  .footer-pc {
    display: none;
  }
}

@media (min-width: 769px) {
  .home {
    display: flex;
    height: 100vh;
    background-image: url('https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/bg1.png');
    background-size: cover;
  }

  .left-third {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-two-thirds {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-content {
    transition: transform 1s ease;
    padding: 0 40px;
    text-align: left;
    color: white;
  }

  .title {
    font-family: 'SHS-B';
    font-size: 32px;
    font-weight: bold;
    color: rgb(204, 118, 1);
  }

  .btn_main {
    color: white;
    width: 158px;
    height: 44px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: relative;
    cursor: pointer;
  }

  .btn_main:hover {
    transform: scale(1.05);
  }

  .btn_main.one {
    background: url('@/assets/img/btn_download.png') no-repeat 50% 0 / 100%;
  }
  .btn_main.one:hover {
    background-color: #e60000; /* 悬停时更深的红色 */
  }

  .btn_main.two {
    /* background: url('@/assets/img/btn_download.png') no-repeat 50% 0 / 100%; */
    background: rgb(42, 119, 196);
  }
  .btn_main.two:hover {
    background-color: rgb(57, 149, 241);
    /* background: linear-gradient(135deg, rgb(42, 119, 196), rgb(45, 150, 255)); */
  }

  .image-box {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 70px;
  }

  .image-border {
    width: 100%;
    height: auto;

    transition: opacity 1s ease-in-out;
  }

  .image-inner {
    position: absolute;
    width: 76%; /* 这里可以调整内部图片大小 */
    height: auto;
    top: 36.4%;
    left: 50.4%;
    border-radius: 3% 3% 0 0;
    transform: translate(-50%, -50%); /* 将图片居中 */
    transition: opacity 1s ease-in-out;
  }

  .text-slide-enter-active,
  .text-slide-leave-active {
    transition: transform 1s;
  }

  .text-slide-enter,
  .text-slide-leave-to {
    transform: translateX(50%);
  }

  .text-slide-leave-active {
    transform: translateX(-100%);
  }

  .image-fade-enter-active,
  .image-fade-leave-active {
    transition: opacity 1s ease-in-out;
  }

  .image-fade-enter,
  .image-fade-leave-to {
    opacity: 0;
  }

  .image-fade-enter-to {
    opacity: 1;
  }

  .footer-pc {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; /* 宽度占满整个屏幕 */
    text-align: center; /* 文本居中 */
    color: white;
    /* text-decoration: none; */
  }

  .footer-pc a {
    text-decoration: none; /* 去除链接的下划线 */
    color: inherit; /* 继承父元素的文本颜色 */
  }
  .footer-pc p {
    font-size: 10px;
  }

  .footer-pc a:hover {
    text-decoration: underline; /* 鼠标悬停时显示下划线 */
  }
  .footer-phone {
    display: none;
  }
}
</style>


<style>
@media (max-width: 768px) {
  .btn_main {
    color: white;
    width: 158px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    cursor: pointer;
    margin: 30px auto; /* 左右居中的关键 */

    border-radius: 10px;
  }

  .btn_main:hover {
    transform: scale(1.05);
  }

  .btn_main.one {
    background: url('@/assets/img/btn_download.png') no-repeat 50% 0 / 100%;
  }
  .btn_main.one:hover {
    background-color: #e60000; /* 悬停时更深的红色 */
  }

  .btn_main.two {
    /* background: url('@/assets/img/btn_download.png') no-repeat 50% 0 / 100%; */
    background: rgb(42, 119, 196);
  }
  .btn_main.two:hover {
    background-color: rgb(57, 149, 241);
    /* background: linear-gradient(135deg, rgb(42, 119, 196), rgb(45, 150, 255)); */
  }
}

@media (min-width: 769px) {
  .btn_main {
    color: white;
    width: 158px;
    height: 44px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    position: relative;
    cursor: pointer;
  }

  .btn_main:hover {
    transform: scale(1.05);
  }

  .btn_main.one {
    background: url('@/assets/img/btn_download.png') no-repeat 50% 0 / 100%;
  }
  .btn_main.one:hover {
    background-color: #e60000; /* 悬停时更深的红色 */
  }

  .btn_main.two {
    /* background: url('@/assets/img/btn_download.png') no-repeat 50% 0 / 100%; */
    background: rgb(42, 119, 196);
  }
  .btn_main.two:hover {
    background-color: rgb(57, 149, 241);
    /* background: linear-gradient(135deg, rgb(42, 119, 196), rgb(45, 150, 255)); */
  }
}
</style>

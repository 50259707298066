<template>
  <div id="app">
    <Navbar />
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src

import Navbar from '@/views/Navbar.vue'

export default {
  name: 'HomeView',
  components: {
    Navbar,
  },
  mounted () {
    // window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy () {
    // window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    // handleScroll () {
    //   const scrollTop = window.scrollY || document.documentElement.scrollTop;
    //   const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    //   const documentHeight = document.documentElement.scrollHeight;

    //   if (scrollTop + windowHeight >= documentHeight) {
    //     console.log('页面触底了');
    //     // 这里可以添加触底后的逻辑
    //   }
    // }
  }
}
</script>
<style>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/Home.vue'
import Component from '@/views/Component.vue'
import InstallationGuide from '@/views/InstallationGuide.vue'
import Download from '@/views/Download.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/component',
		name: 'component',
		component: Component
	},
	{
		path: '/download',
		name: 'download',
		component: Download
	},
	{
		path: '/installationGuide',
		name: 'InstallationGuide',
		component: InstallationGuide
	},
	{
		path: '/about',
		name: 'about',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

//router/index.js
router.afterEach((to, from) => {


	// 设置标题
	document.title = to.query.title || to.meta.title || "XTab - 新标签页插件 | 个性化浏览体验";
});
export default router



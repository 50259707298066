<template>
  <div class="home">
    <div class="left-third">
      <transition name="text-slide"
                  mode="out-in">
        <div class="left-content"
             v-if="showText">
          <h1 class="title">
            {{title}}
          </h1>
          <p>{{desc}}</p>
          <div class="btn_main one"
               @click="$router.push('/download')">
            <span>开始使用</span>
          </div>
          <div class="btn_main two"
               style="left: 180px;top: -44px;"
               @click="openWeb">
            <span>快速体验</span>
          </div>
        </div>
      </transition>
    </div>
    <div class="right-two-thirds">
      <transition name="image-fade"
                  mode="out-in">

        <!-- showImage 会导致闪烁 -->
        <!-- v-show="showImage" -->
        <div key="about-image"
             class="image-box">
          <div @wheel.stop>

            <h1>扩展安装说明</h1>
            <h3>如何安装crx文件?</h3>

            <p>(1) 从设置->更多工具->扩展程序打开扩展程序页面，或者地址栏输入 chrome://extensions/ 按下回车打开扩展程序页面</p>
            <img src="@/assets/img/az1.webp"
                 alt="关于页面的边框图片"
                 class="image-border" />
            <p>(2) 打开扩展程序页面的“开发者模式”</p>
            <img src="@/assets/img/az2.webp"
                 alt="关于页面的边框图片"
                 class="image-border" />
            <p>(3) 将crx文件拖拽到扩展程序页面，完成安装</p>
            <img src="@/assets/img/az3.webp"
                 alt="关于页面的边框图片"
                 class="image-border" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InstallationGuide',
  data () {
    return {
      title: "CRX安装指南",
      desc: "",


      showText: false,
      showImage: false
    };
  },
  watch: {
    '$route' () {
      this.triggerAnimation();
    }
  },
  mounted () {
    this.triggerAnimation();
  },
  methods: {
    openWeb () {
      window.open('https://go.xtab.link/')
    },
    triggerAnimation () {
      this.showText = false;
      this.showImage = false;
      setTimeout(() => {
        this.showText = true;
        this.showImage = true;
      }, 100);
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .home {
    /* position: relative; */
    /* padding-bottom: 120px; */

    background-image: url('https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/bg3.jpg');
    background-size: cover;
    padding-top: 100px;
    min-height: 100vh;
  }

  .left-third {
    justify-content: center;
    align-items: center;
    position: relative;
    /* top: 100px; */
  }

  .right-two-thirds {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* top: 150px; */
  }

  .left-content {
    transition: transform 1s ease;
    padding: 0 40px;
    /* text-align: left; */
  }

  .title {
    color: white;
    font-family: 'SHS-B';
    font-size: 36px;
    font-weight: bold;
    line-height: 1;

    display: inline-block;
  }


  .image-box {
    color: white;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-border {
    width: 320px;
    height: auto;
    transition: opacity 1s ease-in-out;
  }

  .image-inner {
    position: absolute;
    width: 76%; /* 这里可以调整内部图片大小 */
    height: auto;
    top: 37.4%;
    left: 50.4%;
    transform: translate(-50%, -50%); /* 将图片居中 */
    transition: opacity 1s ease-in-out;
  }

  .text-slide-enter-active,
  .text-slide-leave-active {
    transition: transform 1s;
  }

  .text-slide-enter,
  .text-slide-leave-to {
    transform: translateX(50%);
  }

  .text-slide-leave-active {
    transform: translateX(-100%);
  }

  .image-fade-enter-active,
  .image-fade-leave-active {
    transition: opacity 1s ease-in-out;
  }

  .image-fade-enter,
  .image-fade-leave-to {
    opacity: 0;
  }

  .image-fade-enter-to {
    opacity: 1;
  }
}
@media (min-width: 769px) {
  .home {
    display: flex;
    /* background: red; */
    padding-bottom: 45px;
    background-image: url('https://xgfile-web.oss-cn-beijing.aliyuncs.com/img/bg3.jpg');
    background-size: cover;
    min-height: 95.1vh;
  }

  .left-third {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-two-thirds {
    flex: 2;
    display: flex;
  }

  .left-content {
    transition: transform 1s ease;
    padding: 0 40px;
    text-align: left;
    color: white;
  }

  .title {
    font-family: 'SHS-B';
    font-size: 32px;
    font-weight: bold;
    color: rgb(204, 118, 1);
  }
 

  .image-box {
    color: white;
    margin-top: 50px;
  }

  .image-border {
    width: 320px;
    height: auto;
    transition: opacity 1s ease-in-out;
  }

  .image-inner {
    position: absolute;
    width: 76%; /* 这里可以调整内部图片大小 */
    height: auto;
    top: 37.4%;
    left: 50.4%;
    transform: translate(-50%, -50%); /* 将图片居中 */
    transition: opacity 1s ease-in-out;
  }

  .text-slide-enter-active,
  .text-slide-leave-active {
    transition: transform 1s;
  }

  .text-slide-enter,
  .text-slide-leave-to {
    transform: translateX(50%);
  }

  .text-slide-leave-active {
    transform: translateX(-100%);
  }

  .image-fade-enter-active,
  .image-fade-leave-active {
    transition: opacity 1s ease-in-out;
  }

  .image-fade-enter,
  .image-fade-leave-to {
    opacity: 0;
  }

  .image-fade-enter-to {
    opacity: 1;
  }
}
</style>
